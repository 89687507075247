body {
  background-color: #101820ff;
  margin: 0px;
  font-family: sans-serif;
}

.top {
  background-color: #a2a2a1ff;
  margin: 0px;
}

.main-app {
  margin-left: 20px;
  margin-right: 5%;
}

.title {
  color: #006b38ff;
  text-align: center;
  font-weight: bold;
  font-family: sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px;
}

h4 {
  color: #006b38ff;
}
h1 {
  color: #a2a2a1ff;
}

.menu {
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #006b38ff;
  overflow: hidden;
}

.story-list {
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.card-div {
  border: 2px solid #a2a2a1ff;
  margin: 3px;
  flex-basis: 32%;
}
.card-div:hover {
  background-color: #a2a2a1ff;
}

.audio-div {
  text-align: center;
  margin-bottom: 10px;
}

@media all and (max-width: 850px) {
  .menu {
    flex-wrap: wrap;
  }
  .menu > li {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 50%;
    border-bottom: 1px solid #101820ff;
    border-right: 1px solid #101820ff;
  }
  li a {
    padding: 5px 16px;
  }

  .card-div {
    flex-basis: 48%;
  }
}

li a {
  display: block;
  color: #101820ff;
  text-align: center;
  padding: 20px 16px;
  text-decoration: none;
  font-size: large;
  font-weight: bold;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  background-color: #a2a2a1ff;
}

.active {
  color: #a2a2a1ff;
}

a.active:hover {
  background-color: #006b38ff;
}
